import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { resetNewPasswordService } from "../../../services/CommonServices/regiserUserServices";
import styles from "../../../assets/css/Global.css";
import Login_image from "../../../assets/Images/login_image.jpg"
import { toast } from "react-toastify";
const Index = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetToken, setResetToken] = useState("");
  const { id } = useParams();
  const Navigate=useNavigate()

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      resetNewPasswordService({ newPassword: newPassword, token: resetToken })
        .then((res) => {
          console.log(res, "password changed");
          toast.success(res.data?.message)
          Navigate("/")
        })
        .catch((error) => {
          toast.error(error?.response.data.message)
          console.log(error);
        });
   
    } else {
      console.log("Passwords do not match");
      toast.info("Passwords do not match")
    }
  };
  useEffect(() => {
    if (id) {
      setResetToken(id);
    }
  }, []);

  return (
    <Container className="mt-5">
    <Row className="d-flex justify-content-evenly">
      <Col md={6}>
        <div>
          <img src={Login_image} />
        </div>
      </Col>
      <Col md={4}>
        <h2 className="text-center mb-3">Reset Password</h2>
        <Form onSubmit={handleSubmit}>
            <div
             className="auth-form-container"
            >
              <FormGroup>
                <div className="text-left">
                  <Label
                    for="newPassword"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    New Password
                  </Label>
                </div>
                <Input
                  type="password"
                  id="newPassword"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
              </FormGroup>
              <FormGroup>
                <div className="text-left">
                  <Label
                    for="confirmPassword"
                    style={{ textAlign: "left", marginBottom: "10px" }}
                  >
                    Confirm Password
                  </Label>
                </div>
                <Input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </FormGroup>
              <Button color="primary" disabled={confirmPassword==""||newPassword==""} type="submit">
                Change Password
              </Button>
            </div>
          </Form>
      </Col>
    </Row>
  </Container>
  
    
  );
};

export default Index;
