import React, { useEffect, useState } from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import styles from "./Index.module.css";
import "../../../../../assets/css/Global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { deletePlanServices, getAllPlansServices } from "../../../../../services/AdminServices/planService";
// import "react-js-pagination/dist/styles.css";
import loader from "../../../../../assets/Images/loader.gif"
import Select from "react-select";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";

const Index = () => {
  const user = JSON.parse(localStorage.getItem("key"));
  const [planList, setPlanList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(planList.length).fill(false)
  );
  const [loaderstatus,setLoaderStatus]=useState(false);
  const [pageNumber,setPageNumber]=useState(1)
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const Navigate = useNavigate();
  const handlePageChange=(pageNumber)=> {
    console.log(`active page is ${pageNumber}`);
    setPageNumber(pageNumber)
  }
  const itemsPerPage=10
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = planList.slice(startIndex, endIndex);



  var listingdata;
  const getAllPlanData = () => {
    setLoaderStatus(true)
    getAllPlansServices()
      .then((res) => {
        console.log("plan------>",res?.data.data)
        setPlanList(res?.data.data);
        setLoaderStatus(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDropdown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };

  if (!user) {
    Navigate("/");
  }
  const openDeleteModal = (id) => {
    setDeleteUserId(id);
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteUserId("");
    setDeleteModal(false);
  };

  const handlePlanDelete = (id) => {
    deletePlanServices(id)
      .then((res) => {
        toast.success("Plan Deleted Successfully");
        getAllPlanData()
        closeDeleteModal();
      })
      .catch((error) => {
        console.log(error);
        closeDeleteModal();
      });
  };
  useEffect(() => {
    if (user?.data) {
      getAllPlanData();
    } else {
      Navigate("/");
    }
  }, [Navigate]);

  return (
    <div>
      <div className="page_heading">
        <div className="component-header d-flex justify-content-between ">
          <div className="d-flex" style={{width:"50%"}} >
          <span className="fs-4">Plan List</span>
          <div className="react-select" >
            <Select
              // defaultValue={selectedOption}
              // onChange={handleSelect}
              // options={options}
            />
          </div>
          </div>
         <div>
         <Link to="/addplan" >
          <Button color="primary fs-6" className="">Add Plan</Button>
         </Link>
         </div>
        </div>
       
      </div>

      <div>
        <div className={`mt-3 ${styles.table_max_height}`}>
        {! loaderstatus&& <Table hover className="appraisal_table table_headerfix">
            <thead className="table_header">
              <tr>
                <th
                  className="text-center ml-2 border-top-0"
                  style={{ width: "75px" }}
                >
                  #
                </th>
                <th className="text-left border-top-0">Name</th>
                <th className="text-left border-top-0">Price</th>
                <th className="text-left border-top-0 ">Description</th>
                <th className="text-left border-top-0">Quaterly Price</th>
                <th className="text-left border-top-0">Annual Price</th>

                <th
                  className="text-left border-top-0"
                  style={{ width: "95px" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className={styles.table_body}>
              {currentPageData.length > 0 &&
                planList.map((item, i) => (
                  <tr key={i}>
                    <th className="text-center align-top" scope="row">
                      {i + 1}
                    </th>
                    <td className="text-left align-top">{item.name}</td>
                    <td className="text-left align-top">{item.price}</td>
                    <td className="text-left">{item.description}</td>
                    <td className="text-left">{item.quaterly_price}</td>
                    <td className="text-left">{item.annual_price}</td>
                    <td className="threedot text-left ">
                      <Dropdown
                        isOpen={dropdownOpen[i]}
                        toggle={() => toggleDropdown(i)}
                      >
                        <DropdownToggle
                          className="custom-dropdown-toggle"
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                            border: "0px",
                          }}
                        >
                          <span>. . .</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link
                          to={"/editplan/"+item._id}
                          >
                            <DropdownItem>Edit</DropdownItem>
                          </Link>

                          <DropdownItem
                           onClick={() => openDeleteModal(item._id)}
                          >Delete</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}

              {planList.length == 0 && (
                <tr className="nobg-hover">
                  <td colSpan="5" className="text-center">
                    <div className="norecords">RECORD NOT FOUND</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>}
         {loaderstatus&& <div >
            <img src={loader} className="loader" />
          </div>}
        </div>
        <div className="mt-2" >
          {planList && planList.length > 0 && (
            <Pagination
              activePage={pageNumber}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={planList.length}
              pageRangeDisplayed={654}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center"
              activeLinkClass="active"
              nextPageText="Next"
              prevPageText="Previous"
            />
          )}
          </div>
      </div>
      <Modal size="ml" isOpen={deleteModal} toggle={() => closeDeleteModal()}>
        <div className="modal-content">
          <ModalHeader
            className="header-less"
            toggle={() => closeDeleteModal()}
          >
            <div>Plan</div>
          </ModalHeader>
          <ModalBody className="border-0 text-left">
            Are you sure want to delete plan ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => closeDeleteModal()}>
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={() => handlePlanDelete(deleteUserId)}
            >
              Delete
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
