// RegisterPage.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
  FormFeedback,
} from "reactstrap";
import {REACT_APP_CLIENT_ID} from "../../../constants/config"
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../assets/css/Global.css";
import Login_image from "../../../assets/Images/login_image.jpg";
import {
  loginUserService,
  googleLoginUserService,
} from "../../../services/CommonServices/regiserUserServices";
import { toast } from "react-toastify";
// import PlanPage from "../../../pages/MemberShipCard/Index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Styles from './Index.module.css';

const LoginPage = () => {
  const user = JSON.parse(localStorage.getItem("key"));
  const [numberLogin, setNumberLogin] = useState(false);
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    mobile: "",
  });
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email is required.",
          }));
        }
        break;
      case "password":
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "Password is required.",
          }));
        }
        break;
      case "mobile":
        setErrors((prevErrors) => ({ ...prevErrors, mobile: "" }));
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            mobile: "Mobile number is required.",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("login click");
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
    });

    console.log("Form submitted:", formData);
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );
    loginUserService(filteredFormData)
      .then((res) => {
        if (res?.data.statusCode == 200) {
          localStorage.setItem("key", JSON.stringify(res?.data));
          console.log(res?.data, "Login Successfull");
          toast.success("Login Successfully");
          Navigate("/userlist");
        } else {
          // console.log(res?.data.error[0].error,"9999999999")
          toast.error(res?.data.error[0].error);
        }
      })
      .catch((error) => {
        console.log(error, "error catch");
        toast.error(error?.response?.data.message[0]);
      });
  };
//function
  const handleformtoggle = () => {
    setNumberLogin(!numberLogin);
    if (numberLogin) {
      setFormData({ email: "", password: "" });
    } else {
      setFormData({ mobile: "", password: "" });
    }
  };

  useEffect(() => {
    if (user?.data) {
      Navigate("/userlist");
    }
  }, []);

  return (
    <Container className="mt-5">
      <Row className="d-flex justify-content-evenly">
        <Col md={6}>
          <div>
            <img src={Login_image} />
          </div>
        </Col>
        <Col md={4}>
          <h2 className="text-center mb-3">Login Page</h2>
          <Form onSubmit={handleSubmit}>
            <div className="auth-form-container">
              <Row>
                {/* Email */}
                  {!numberLogin && (
                    <FormGroup>
                      <div className="label-start">
                        <Label for="email">Email</Label>
                      </div>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={errors.email !== ""}
                      />
                      <div className="text-start">
                        <span className="text-danger">{errors.email}</span>
                      </div>
                    </FormGroup>
                  )}
                  {numberLogin && (
                    <FormGroup>
                      <div className="label-start">
                        <Label for="mobile">Number</Label>
                      </div>
                      <Input
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Enter Your Number"
                        value={formData.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={errors.mobile !== ""}
                      />
                      <div className="text-start">
                        <span className="text-danger">{errors.mobile}</span>
                      </div>
                    </FormGroup>
                  )}
              </Row>

              <Row>
                {/* Password */}
                  <FormGroup>
                    <div className="label-start">
                      <Label for="password">Password</Label>
                    </div>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={errors.password !== ""}
                    />

                    <div className="text-start">
                      <span className="text-danger">{errors.password}</span>
                    </div>
                    <div className="text-left mt-2">
                      <Link to="/reset-password">Forgot Password?</Link>
                    </div>
                  </FormGroup>
              </Row>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Link to="/register">
                    <Button
                      color="link"
                      style={{ textDecoration: "none", color: "#686868" }}
                    >
                      Don't have an account?{" "}
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button color="primary" type="submit">
                    Login
                  </Button>
                </div>
              </div>
              <div 
                  className="btn btn-secondry border d-block  mt-2"
                  onClick={handleformtoggle}
                   
              >
                <span
                >
                  Login With {numberLogin ? "Email" : "Mobile Number"}
                </span>
              </div>
              <div 
              className={`mt-2 p2 align-center ${Styles.googleIcon}`}

              >
                <GoogleOAuthProvider clientId={REACT_APP_CLIENT_ID}>
                  <GoogleLogin
                    width="370px"
                    onSuccess={async (credentialResponse) => {
                      console.log(credentialResponse, "bac88");

                      googleLoginUserService({
                        token: credentialResponse.credential,
                      })
                        .then((res) => {
                          localStorage.setItem(
                            "key",
                            JSON.stringify(res?.data)
                          );
                          toast.success("Login Successfully");
                          Navigate("/userlist");
                        })
                        .catch((error) => {
                          console.log(error, "error catch");
                          toast.error(error?.response?.data.message[0]);
                        });
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    // useOneTap
                  />
              
                </GoogleOAuthProvider  >
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      {/* <Row className="d-flex justify-content-evenly">
        <PlanPage />
      </Row> */}
    </Container>
  );
};

export default LoginPage;
