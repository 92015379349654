import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../components/users/Admin/Dashboard/Index";
import RegisterPage from "../components/Auth/Register/Index";
import LoginPage from "../components/Auth/Login/Index";
import UserList from "../components/users/Admin/RegisteredUser/Index"
import PageNotFound from "../components/page_Not_Found/Index"
import ResetPasswordPage from "../components/Auth/ResetPassword/Index";
import EditProfilePage from "../components/users/Admin/EditProfile/Index"
import AddNewPassword from "../components/Auth/AddNewPassword/Index"
import PlanListing from "../components/users/Admin/MemberShip/PlanListing/Index"
import AddPlans from "../components/users/Admin/MemberShip/AddPlan/Index"
// import StripePayment from "../components/users/Admin/StripePayment/Index"
import EditPlans from "../components/users/Admin/MemberShip/EditPlan/Index"
import PlanPage from "../pages/MemberShipCard/Index"
import ResendEmailVerify from "../components/Auth/ResendEmailVerification/Index"
const PublicRoutes = () => {
  return (
    <>
      <Routes>
         <Route path="*" element={<PageNotFound/>} />
         <Route path="/" element={<LoginPage/>} />
         <Route path="/dashboard" element={<Main />} />
         <Route path="/register" element={<RegisterPage />} />
         <Route path="/userlist" element={<UserList/>} />

         <Route path="/reset-password" element={<ResetPasswordPage/>} />
         <Route path="/resendemailverify" element={<ResendEmailVerify/>} />

         <Route path="auth/reset/:id" element={<AddNewPassword/>} />
         <Route path="/editprofile/:id" element={<EditProfilePage/>} />
         <Route path="/planlist" element={<PlanListing/>} />
         <Route path="/addplan" element={<AddPlans/>} />
         <Route path="/editplan/:id" element={<EditPlans/>} />
         {/* <Route path="/stripe" element={<StripePayment/>} /> */}
         <Route path="/planpage" element={<PlanPage/>} />






       
      </Routes>
    </>
  );
};

export default PublicRoutes;
