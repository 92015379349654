import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { registerUserService } from "../../../services/CommonServices/regiserUserServices";
import Login_image from "../../../assets/Images/login_image.jpg";

const RegisterPage = () => {
  const user = JSON.parse(localStorage.getItem("key"));
  const [formData, setFormData] = useState({
    name: "",
    mobile: null,
    email: "",
    password: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const Navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateField = (name, value) => {
    let errors = { ...validationErrors };

    switch (name) {
      case "name":
        errors[name] = value.trim() === "" ? "Please enter your name" : "";
        break;
      case "email":
        errors[name] = value.trim() === "" ? "Please enter your email" : "";
        errors[name] =
          !value.includes("@") 
            ? "Please enter a valid email address"
            : "";
        break;
      case "mobile":
        errors[name] =
          !value || isNaN(value) || value.toString().length !== 10
            ? "Please enter a valid 10-digit mobile number"
            : "";
        break;
      case "password":
        errors[name] = value.trim() === "" ? "Please enter your password" : "";
        break;
      default:
        break;
    }

    setValidationErrors(errors);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const [name, value] of Object.entries(formData)) {
      validateField(name, value);
    }
    if (Object.values(validationErrors).some((error) => error !== "")) {
      toast.error("Please fix the errors before submitting the form");
      return;
    }
    console.log("Form submitted:", formData);
    registerUserService(formData)
      .then((res) => {
        console.log(res, "user registered");
        toast.success("User Registered Successfully");
        toast.info("Please Verify Your Email");

        Navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (user?.data) {
      Navigate("/userlist");
    }
  }, [user, Navigate]);

  return (
    <Container className="mt-5">
      <Row className="d-flex justify-content-evenly">
        <Col md={6}>
          <div>
            <img src={Login_image} />
          </div>
        </Col>
        <Col md={4}>
          <h2 className="text-center mb-3">Registration Page</h2>
          <Form onSubmit={handleSubmit}>
          <div className="auth-form-container">
            <Row>
              <FormGroup>
                <div className="text-start">
                  <Label for="name">First Name</Label>
                </div>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Your First Name"
                  value={formData.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="text-start">

                {validationErrors.name && (
                  <div className="text-danger">{validationErrors.name}</div>
                )}
                </div>
              </FormGroup>

              <FormGroup>
                <div className="label-start">
                  <Label for="email">Email</Label>
                </div>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="text-start">

                {validationErrors.email && (
                  <div className="text-danger">{validationErrors.email}</div>
                )}
                </div>
              </FormGroup>

              <FormGroup>
                <div className="label-start">
                  <Label for="mobile">Mobile</Label>
                </div>
                <Input
                  type="mobile"
                  name="mobile"
                  id="mobile"
                  placeholder="Enter Your Mobile "
                  value={formData.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="text-start">

                {validationErrors.mobile && (
                  <div className="text-danger">{validationErrors.mobile}</div>
                )}
                </div>
              </FormGroup>
            </Row>

            <FormGroup>
              <div className="label-start">
                <Label for="password">Password</Label>
              </div>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Enter Your Password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
                <div className="text-start">

              {validationErrors.password && (
                <div className="text-danger">{validationErrors.password}</div>
              )}
              </div>
            </FormGroup>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Link to="/">
                  <Button color="link" style={{ textDecoration: "none",color:"#686868" }}>
                    Already have an account?{" "}
                  </Button>
                </Link>
              </div>
              <div>
                <Button color="primary" type="submit">
                  Signup
                </Button>
              </div>
            </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
