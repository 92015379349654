import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getAllPlansServices } from "../../services/AdminServices/planService";
import { useNavigate } from "react-router-dom";
import { PaymentService } from "../../services/CommonServices/regiserUserServices";

const PaymentCards = () => {
  const user = JSON.parse(localStorage.getItem("key"));
  const [planList, setPlanList] = useState([]);
  const Navigate = useNavigate();

  const getAllPlanData = () => {
    getAllPlansServices()
      .then((res) => {
        setPlanList(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePayment = (data) => {
    PaymentService(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log("err while payment", err);
      });
  };

  useEffect(() => {
    if (user?.data) {
      getAllPlanData();
    } else {
      Navigate("/");
    }
  }, []);

  return (
    <div className="card-container mt-4 p-4" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "40px" }}>
      {planList.map((plan, index) => (
        <div key={index} className="card mb-4 shadow-sm plan-card ">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">{plan.name}</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">
              {`₹${plan.price}`}
              <small className="text-muted">/ mo</small>
            </h1>
            <ul className="list-unstyled mt-3 mb-4" style={{height:"200px"}}>
              <li>{plan.description}</li>
            </ul>
            <button
              className="btn btn-lg btn-block btn-primary"
              type="button"
              onClick={() =>
                handlePayment({
                  price: plan.price,
                  email: user?.user.email,
                  name: user?.user.name,
                })
              }
            >
              Select Plan
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaymentCards;
