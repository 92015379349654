import axios from "axios";
import { API_URL } from "../../constants/config";

export const getAllPlansServices = () => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "get",
    url: API_URL + "plan",
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const addPlanService = (data) => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "post",
    url: API_URL + "plan/add",
    data: data,
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const getEditPlansServices = (id) => {
  const user = JSON.parse(localStorage.getItem("key"));
  const Token = user?.data;

  const axiosConfig = {
    method: "get",
    url: API_URL + "plan/" + id,
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const postPlanService = (id, data) => {
  const user = JSON.parse(localStorage.getItem("key"));
  const Token = user?.data;
  const axiosConfig = {
    method: "post",
    url: API_URL + "plan/" + id,
    data: data,
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const deletePlanServices = (payload) => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "delete",
    url: API_URL + "plan/" + payload,
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
