import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsers, faTools, faEnvelope, faGem } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../assets/Images/logo.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

const Sidebar = () => {
  return (
    <div className="sidebar" style={{ textAlign: "left" }}>
      <Nav vertical>
        <div style={{ margin: "24px 30px" }}>
          <img src={Logo} alt="Logo" />
        </div>
        <div style={{ marginTop: "30px" }}>
          <NavItem className='ancor-tag sidebar-list'>
            <FontAwesomeIcon  icon={faTachometerAlt} /> 
            <Link to="/dashboard">Dashboard</Link>
          </NavItem>
          <NavItem className='ancor-tag sidebar-list'>
            <FontAwesomeIcon  icon={faUsers} />
            <Link to="/userlist">User List</Link>
          </NavItem>
          <NavItem className='ancor-tag sidebar-list'>
            <FontAwesomeIcon icon={faTools} />
            <Link to="/service" >Services</Link>
          </NavItem>
          <NavItem className='ancor-tag sidebar-list'>
            <FontAwesomeIcon  icon={faGem} /> 
            <Link to="/planlist">Plans</Link>
          </NavItem>
        </div>
      </Nav>
    </div>
  );
};

export default Sidebar;
