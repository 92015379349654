import React from "react";
import ResponsiveAppBar from "../NavBar/index";
import Sidebar from "../Sidebar/Index";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();

  const hideSidebarAndNavbar =
    location.pathname === "/" ||
    location.pathname === "/register" ||
    location.pathname === "/reset-password" ||
    // location.pathname === "/planpage" ||
    location.pathname.startsWith("/auth/reset/");
  return (
    <div style={{ display: "flex" }}>
      {!hideSidebarAndNavbar && (
        <div
          style={{
            width: "15%",
            minHeight:"100vh",
            borderRight: "1px solid black",
          }}
        >
          <Sidebar />
        </div>
      )}

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {!hideSidebarAndNavbar && (
          <div style={{ width: "100%" }}>
            <ResponsiveAppBar />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Layout;
