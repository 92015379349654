// import { authHeader, check419 } from "../helpers";
import axios from "axios";
import { API_URL } from "../../constants/config";

export const registerUserService = (payload) => {
  const axiosConfig = {
    method: "post",
    url: API_URL + "user",
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const loginUserService = (payload) => {
  const axiosConfig = {
    method: "post",
    url: API_URL + "auth/login",
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const googleLoginUserService = (payload) => {
  const axiosConfig = {
    method: "post",
    url: API_URL + "auth/google/login",
    data: payload,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const resetPasswordService = (data) => {
  const axiosConfig = {
    method: "post",
    url: API_URL + "auth/forgot-password",
    data: data,
    headers: {
      "Content-type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const resendEmailService = (data) => {
  const axiosConfig = {
    method: "post",
    url: API_URL + "auth/resend-verify-email",
    data: data,
    headers: {
      "Content-type": "application/json",
    },
  };
  return axios(axiosConfig);
};


export const resetNewPasswordService = (payload) => {
  // console.log(payload.newPassword)
  const axiosConfig = {
    method: "post",
    url: API_URL + "auth/reset-password/" + payload.token,
    data: { password: payload.newPassword },
    headers: {
      "Content-type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const PaymentService = (data) => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "post",
    url: API_URL + "stripe-payment/create-checkout-session",
    data: data,
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-type": "application/json",
    },
  };
  return axios(axiosConfig);
};
