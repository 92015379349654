import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

const Index = () => {
  const user = JSON.parse(localStorage.getItem("key"));

  useEffect(() => {}, []);

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(4,1fr)",padding:"10px",justifyContent:"space-between" }}>
        <div
            className="dashboard-card-container"
        >
          <div style={{ height: "80%", display: "flex" }}>
            <div style={{ width: "60%", height: "100%" }}>
            15<br/>
              Email
            </div>
            <div
              style={{ width: "35%", textAlign: "center",  }}
            >
              <i
                class="fa-solid fa-envelope"
                style={{ fontSize: "50px", margin: "auto" }}
              ></i>
            </div>
          </div>
          <div style={{ backgroundColor: "#dddd" }}>
            <span>Details</span>
          </div>
        </div>

        <div
           className="dashboard-card-container"
        >
          <div style={{ height: "80%", display: "flex" }}>
            <div style={{ width: "60%", height: "100%" }}>
            15<br/>
              User
            </div>
            <div
              style={{ width: "35%", textAlign: "center",  }}
            >
              <i
                class="fa-regular fa-user"
                style={{ fontSize: "50px", margin: "auto" }}
              ></i>
            </div>
          </div>
          <div style={{ backgroundColor: "#dddd" }}>
            <span>Details</span>
          </div>
        </div>
        <div
           className="dashboard-card-container"
        
        >
          <div style={{ height: "80%", display: "flex" }}>
            <div style={{ width: "60%", height: "100%" }}>
              15<br/>
              Service
            </div>
            <div
              style={{ width: "35%", textAlign: "center",  }}
            >
            
             
              <i   style={{ fontSize: "50px", margin: "auto" }}  class="fa-brands fa-usps"></i>
            </div>
          </div>
          <div style={{ backgroundColor: "#dddd" }}>
            <span className="btn-primary" >Details</span>
          </div>
        </div>
        <div
           className="dashboard-card-container"
           
        >
          <div style={{ height: "80%", display: "flex" }}>
            <div style={{ width: "60%", height: "100%" }}>
            150<br/>
              Contact
            </div>
            <div
              style={{ width: "35%", textAlign: "center",  }}
            >
              <i
                style={{ fontSize: "50px", margin: "auto" }}
                class="fa-regular fa-address-book"
              ></i>
            </div>
          </div>
          <div style={{ backgroundColor: "#dddd" }}>
            <span>Details</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
