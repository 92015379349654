import React, { useEffect, useState } from "react";
import {
  getAllUserServices,
  deleteUserServices,
  getOneUserServices,
  updateSingleUserServices,
} from "../../../../services/AdminServices/userServices";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./Index.module.css";
import "../../../../assets/css/Global.css";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css"; 
// import "react-js-pagination/dist/styles.css";
import loader from "../../../../assets/Images/loader.gif"

const Index = () => {
  const user = JSON.parse(localStorage.getItem("key"));
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(
    Array(userList.length).fill(false)
  );
  const [loaderstatus,setLoaderStatus]=useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
    image: null,
    status: "",
  });
  const [userId, setUserId] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    value: "all",
    label: "All",
  });
  const [pageNumber,setPageNumber]=useState(1)
  const Navigate = useNavigate();

  const options = [
    { value: "all", label: "All" },
    { value: "user", label: "User" },
    { value: "super admin", label: "Super Admin" },
  ];

  const handleSelect = (e) => {
    if (e.value != "all") {
      let filterData = userList.filter((el) => el.role === e.value);
      setFilterData(filterData);
    } else {
      setFilterData(userList);
    }
  };
  var listingdata;
  const getAllUserData = () => {
    setLoaderStatus(true)
    getAllUserServices()
      .then((res) => {
        setUserList(res?.data.users.data);
        setFilterData(res?.data.users.data);
        setLoaderStatus(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDropdown = (index) => {
    const newDropdownOpen = [...dropdownOpen];
    newDropdownOpen[index] = !newDropdownOpen[index];
    setDropdownOpen(newDropdownOpen);
  };
  const handleUserDelete = (id) => {
    deleteUserServices(id)
      .then((res) => {
        console.log(res, "deleted appraisalList");
        toast.success("User Deleted Successfully");

        getAllUserData();
        closeDeleteModal();
      })
      .catch((error) => {
        console.log(error);
        closeDeleteModal();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, image: file }));
  };

  const handleSubmit = async (event, formData) => {
    event.preventDefault();
    // Handle form submission logic here
    updateSingleUserServices(userId, formData)
      .then((res) => {
        console.log("user edited");
        getAllUserData();
        closeEditModal();
      })
      .catch((error) => {
        console.log(error);
        closeEditModal();
      });
    // empty formdata, close model, getuser
  };

  const openDeleteModal = (id) => {
    setDeleteUserId(id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteUserId("");
    setDeleteModal(false);
  };
  if (!user) {
    Navigate("/");
  }
  const getOneUserData = (id) => {
    getOneUserServices(id)
      .then((res) => {
        console.log(res?.data, "one data,888888");
        setFormData({
          name: res.data.name,
          email: res.data.email,
          mobile: res.data.mobile,
          password: "",
          role: res.data.role,
          image: "",
          status: res.data.status,
          address: res.data.address,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openEditModal = (id) => {
    setUserId(id);
    getOneUserData(id);
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setFormData({
      name: "",
      email: "",
      mobile: "",
      password: "",
      role: "",
      image: "",
      address: "",
      status: "",
    });
  };

  const handlePageChange=(pageNumber)=> {
    console.log(`active page is ${pageNumber}`);
    setPageNumber(pageNumber)
  }
  const itemsPerPage=10
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = filterData.slice(startIndex, endIndex);

  useEffect(() => {
    if (user?.data) {
      getAllUserData();
    } else {
      Navigate("/");
    }
  }, [Navigate]);

  return (
    <div>
      <div className="page_heading">
        <div className="component-header">
          <span className="fs-4">User List</span>

          <div className="react-select">
            <Select
              defaultValue={selectedOption}
              onChange={handleSelect}
              options={options}
            />
          </div>
        </div>
      </div>

      <div>
        <div className={`mt-3 ${styles.table_max_height}`}>
        { !loaderstatus&& <Table hover className="appraisal_table table_headerfix">
            <thead className="table_header">
              <tr>
                <th
                  className="text-center ml-2 border-top-0"
                  style={{ width: "75px" }}
                >
                  #
                </th>
                <th className="text-left border-top-0">Name</th>
                <th className="text-left border-top-0">Email</th>
                <th className="text-left border-top-0 ">Number</th>
                <th className="text-left border-top-0">Role</th>
                <th className="text-left border-top-0">Status</th>

                <th
                  className="text-left border-top-0"
                  style={{ width: "95px" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className={styles.table_body}>
              {currentPageData.length > 0 &&
                currentPageData.map((item, i) => (
                  <tr key={i}>
                    <th className="text-center align-top" scope="row">
                      {i + 1}
                    </th>
                    <td className="text-left align-top">{item.name}</td>
                    <td className="text-left align-top">{item.email}</td>
                    <td className="text-left">{item.mobile}</td>
                    <td className="text-left">{item.role}</td>
                   <td className="text-left">
                    <span className={item.status=="active"?"capsule dim-green":"capsule dim-red"} >{item.status}</span>
                   </td>

                    <td className="threedot text-left ">
                      <Dropdown
                        isOpen={dropdownOpen[i]}
                        toggle={() => toggleDropdown(i)}
                      >
                        <DropdownToggle
                          className="custom-dropdown-toggle"
                          style={{
                            backgroundColor: "transparent",
                            color: "black",
                            border: "0px",
                          }}
                        >
                          <span>. . .</span>
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link
                          // to={`/admin/editAppraisal/${item.appraisals._id}`}
                          >
                            <DropdownItem
                              onClick={() => openEditModal(item._id)}
                            >
                              Edit
                            </DropdownItem>
                          </Link>

                          <DropdownItem
                            onClick={() => openDeleteModal(item._id)}
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              
              {userList.length == 0 && (
                <tr className="nobg-hover">
                  <td colSpan="5" className="text-center">
                    <div className="norecords">RECORD NOT FOUND</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>}
          {loaderstatus&& <div >
            <img src={loader} className="loader" />
          </div>}
        </div>
        <div className="mt-2" >
          {filterData && filterData.length > 0 && (
            <Pagination
              activePage={pageNumber}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={filterData.length}
              pageRangeDisplayed={654}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center"
              activeLinkClass="active"
              nextPageText="Next"
              prevPageText="Previous"
            />
          )}
          </div>
      </div>
      <Modal size="ml" isOpen={deleteModal} toggle={() => closeDeleteModal()}>
        <div className="modal-content">
          <ModalHeader
            className="header-less"
            toggle={() => closeDeleteModal()}
          >
            <div>User</div>
          </ModalHeader>
          <ModalBody className="border-0 text-left">
            Are you sure want to delete appraisal ?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => closeDeleteModal()}>
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={() => handleUserDelete(deleteUserId)}
            >
              Delete
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal size="xl" isOpen={editModal} toggle={() => closeEditModal()}>
        <ModalHeader toggle={() => closeEditModal()}>Edit User</ModalHeader>
        <ModalBody>
          <Form onSubmit={(event) => handleSubmit(event, formData)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="mobile">Number</Label>
                  <Input
                    type="tel"
                    name="mobile"
                    id="mobile"
                    placeholder="Enter your number"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="role">Role</Label>
                  <Input
                    type="select"
                    name="role"
                    id="role"
                    value={formData.role}
                    onChange={handleChange}
                  >
                    <option placeholder="Select role">Select role</option>

                    <option value="user">User</option>
                    <option value="super admin">Super Admin</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="image">Upload Image</Label>
                  <Input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option placeholder="Select status">Select status</option>

                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter your address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={() => closeEditModal()}>
              Cancel
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default Index;
