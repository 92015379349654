import logo from './logo.svg';
import './App.css';
import Index from "./routes/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/Global.css"
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import {ExpireToken} from "./components/Auth/ExpireToken/Index"

function App() {
  useEffect(()=>{
    ExpireToken()
  },[])

  return (
    <div className="App">
      <Index/>
      <ToastContainer position="bottom-right" autoClose={1000}/>
     
    </div>
  );
}

export default App;
