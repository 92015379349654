import React, { useEffect, useState } from "react";
import styles from "../../../../assets/css/Global.css";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
  getOneUserServices,
  updateSingleUserServices,
} from "../../../../services/AdminServices/userServices";
import { Link, useNavigate } from "react-router-dom";

const Index = () => {
  const user = JSON.parse(localStorage.getItem("key"));

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
    image: null,
    status: "",
  });
  const Navigate=useNavigate()

  const handleSubmit = async (event, formData) => {
    event.preventDefault();
    // Handle form submission logic here
    updateSingleUserServices(user.user?._id, formData)
      .then((res) => {
        console.log("user edited");
      })
      .catch((error) => {
        console.log(error);
      });
    // empty formdata, close model, getuser
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getOneUserData = (id) => {
    getOneUserServices(id)
      .then((res) => {
        console.log(res.data, "one data,888888");
        setFormData({
          name: res?.data.name,
          email: res?.data.email,
          mobile: res?.data.mobile,
          // password: res.data.password,
          role: res?.data.role,
          image: "",
          status: res?.data.status,
          address: res?.data.address,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, image: file }));
  };
  
  if (!user) {
    Navigate("/");
  }
  useEffect(() => {
    if(user?.data){
      
      getOneUserData(user.user?._id);
    }else{
      Navigate("/")
    }
  }, [Navigate]);
  return (
    <div>
      <div className="page_heading">
        <div className="component-header">
          <h4>Edit Profile</h4>
        </div>
      </div>
      <div>
        <div className="background">
          <div className="profile-image">
            <img
              style={{ height: "100%" }}
              src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"
            />
          </div>
        </div>
        <div className="user-setting-form">
          <Form onSubmit={(event) => handleSubmit(event, formData)}>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="name">Name</Label>
                  </div>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="email">Email</Label>
                  </div>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="number">Number</Label>
                  </div>
                  <Input
                    type="tel"
                    name="number"
                    id="number"
                    placeholder="Enter your number"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="password">Password</Label>
                  </div>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="role">Role</Label>
                  </div>
                  <Input
                    type="select"
                    name="role"
                    id="role"
                    value={formData.role}
                    onChange={handleChange}
                  >
                    <option placeholder="Select role">Select role</option>

                    <option value="user">User</option>
                    <option value="super admin">Super Admin</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="image">Upload Image</Label>
                  </div>
                  <Input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="status">Status</Label>
                  </div>
                  <Input
                    type="select"
                    name="status"
                    id="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option placeholder="Select status">Select status</option>

                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="label-start">
                    <Label for="address">Address</Label>
                  </div>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter your address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-end" >
           <Link to="/userlist" >
           <Button color="secondary">
              Cancel
            </Button>{" "}
           </Link>
            <Button color="primary" type="submit">
              Submit
            </Button>{" "}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Index;
