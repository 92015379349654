import axios from "axios";
import { API_URL } from "../../constants/config";
const user = JSON.parse(localStorage.getItem("key"));

export const getAllUserServices = () => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "get",
    url: API_URL + "user",
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const deleteUserServices = (payload) => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "delete",
    url: API_URL + "user/" + payload,
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
export const getOneUserServices = (id) => {
  const user = JSON.parse(localStorage.getItem("key"));
  
  const Token = user?.data;
  const axiosConfig = {
    method: "get",
    url: API_URL + "user/" + id,
    data: {},
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};

export const updateSingleUserServices = (id, data) => {
  const user = JSON.parse(localStorage.getItem("key"));

  const Token = user?.data;
  const axiosConfig = {
    method: "post",
    url: API_URL + "user/" + id,
    data: data,
    headers: {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    },
  };
  return axios(axiosConfig);
};
