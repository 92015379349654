import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Login_image from "../../../../../assets/Images/login_image.jpg";
import {
  postPlanService,
  getEditPlansServices,
} from "../../../../../services/AdminServices/planService";

const Index = () => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    quaterly_price: "",
    annual_price: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [submitButton, setSubmitButton] = useState(true);
  const Navigate = useNavigate();
  const { id } = useParams();

  const getEditPlanData = () => {
    getEditPlansServices(id)
      .then((res) => {
        console.log("plan------>", res?.data);
        const data = res?.data;
        setFormData({
          name: data.name,
          price: data.price,
          description: data.description,
          quaterly_price: data.quaterly_price,
          annual_price: data.annual_price,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateField = (name, value) => {
    let errors = { ...validationErrors };

    switch (name) {
      case "name":
        errors[name] = value.trim() === "" ? "Please enter your plan" : "";
        break;
      case "price":
        errors[name] = value.trim() === 0 ? "Please enter price" : "";
        break;
      case "quaterly_price":
        errors[name] = value.trim() === 0 ? "Please enter quaterly price" : "";
        break;
      case "annual_price":
        errors[name] = value.trim() === 0 ? "Please enter annual price" : "";
        break;
      case "description":
        errors[name] = value.trim() === "" ? "Please enter description" : "";
        break;
      default:
        break;
    }

    setValidationErrors(errors);
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleChange = (e) => {
    setSubmitButton(false);
    const { name, value } = e.target;

    if (name == "quaterly_price" || name == "annual_price" || name == "price") {
      setFormData((prevData) => ({ ...prevData, [name]: Number(value) }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    // validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(validationErrors).some((error) => error !== "")) {
      toast.error("Please fix the errors before submitting the form");
      return;
    }
    console.log("Form submitted:", formData);
    postPlanService(id, formData)
      .then((res) => {
        console.log(res, "user registered");
        toast.success("Plan Edited Successfully");
        Navigate("/planlist");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (id) {
      getEditPlanData();
    }
  }, []);

  return (
    <Container className="mt-5">
      <Row md={12}>
        <Col>
          <h2 className="text-center mb-3">Edit Plan</h2>
          <Form onSubmit={handleSubmit}>
            <div className="auth-form-container">
              <Row>
                <Col>
                  <FormGroup>
                    <div className="text-start">
                      <Label for="name">Plan</Label>
                    </div>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Plan Name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-start">
                      {validationErrors.name && (
                        <div className="text-danger">
                          {validationErrors.name}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <div className="label-start">
                      <Label for="price">Price</Label>
                    </div>
                    <Input
                      type="text"
                      name="price"
                      id="price"
                      placeholder="Enter Price"
                      value={formData.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-start">
                      {validationErrors.price && (
                        <div className="text-danger">
                          {validationErrors.price}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <div className="label-start">
                      <Label for="quaterly_price">Quaterly Price</Label>
                    </div>
                    <Input
                      type="text"
                      name="quaterly_price"
                      id="quaterly_price"
                      placeholder="Enter Quaterly Price"
                      value={formData.quaterly_price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-start">
                      {validationErrors.quaterly_price && (
                        <div className="text-danger">
                          {validationErrors.quaterly_price}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <div className="label-start">
                      <Label for="annual_price">Annual Price</Label>
                    </div>
                    <Input
                      type="text"
                      name="annual_price"
                      id="annual_price"
                      placeholder="Enter Annual Price"
                      value={formData.annual_price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="text-start">
                      {validationErrors.annual_price && (
                        <div className="text-danger">
                          {validationErrors.annual_price}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <div className="label-start">
                  <Label for="description">Description</Label>
                </div>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder="Enter Plan Description"
                  value={formData.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="text-start">
                  {validationErrors.description && (
                    <div className="text-danger">
                      {validationErrors.description}
                    </div>
                  )}
                </div>
              </FormGroup>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ marginRight: "10px" }}>
                  <Button color="primary" type="submit" disabled={submitButton}>
                    Submit
                  </Button>
                </div>
                <div>
                  <Link to="/planlist">
                    <Button color="secondary">Cancel</Button>
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Index;
