import React, { useState } from "react";
import {
  Container,
  FormGroup,
  Label,
  Form,
  Button,
  Input,
  Row,
  Col,
} from "reactstrap";
import { resetPasswordService } from "../../../services/CommonServices/regiserUserServices";
import { toast } from "react-toastify";
import Login_image from "../../../assets/Images/login_image.jpg";
import { Link } from "react-router-dom";

function ResetPasswordPage() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    resetPasswordService({ email: email })
      .then((res) => {
        console.log(res);
        setEmail("");
        toast.success("Reset link sent on email");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Container className="mt-5">
        <Row className="d-flex justify-content-evenly">
          <Col md={6}>
            <div>
              <img src={Login_image} />
            </div>
          </Col>
          <Col md={4}>
            <h2 className="text-center mb-3">Reset Password</h2>
            <Form className="auth-form-container">
              <FormGroup>
               <div className="text-left">
               <Label for="exampleEmail">Email</Label>
               </div>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="Enter your registered email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </FormGroup>
             <div className="d-flex justify-content-end" >
            <div className="m-1">
            <Button disabled={email==""} color="primary" size="md " onClick={handleSubmit}>
                Send Link
              </Button>
            </div>
             <div className="m-1">
             <Link to="/">
              <Button  color="secondary" size="md">
                Cancel
              </Button>
              </Link>
             </div>
             </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPasswordPage;
